import { gql } from '@apollo/client';
import { DOG_SHORT_INFO } from './dog-fragments';
import { SPOT_SHORT_INFO } from './spot-fragments';
import { USER_BASE_INFO } from './user-fragments';

export const MSGS_CHANNEL_FRAGMENT = gql`
    fragment ChannelInfo on Channel {
        sid
        uniqueName
        friendlyName
        token
        spots {
            ...SpotShortInfo
        }
        dogs {
            ...DogShortInfo
        }
        members {
            ...UserBaseInfo
        }
    }
    ${SPOT_SHORT_INFO}, ${DOG_SHORT_INFO}, ${USER_BASE_INFO}
`;
