import { SET_MAP_DATA, SET_MARKER_OPEN } from '../actions/map.actions';
import { DEFAULT_MAP_ZOOM } from '../consts/app.consts';

export const INITIAL = {
    searchLocation: {},
    center: {},
    isMarkerOpen: false,
    address: '',
    isFitBounds: false,
    doNotLoadSpots: false,
    zoom: DEFAULT_MAP_ZOOM,
    bounds: { ne: { lat: 0, lng: 0 }, sw: { lat: 0, lng: 0 } },
    serverBounds: { ne: { lat: 0, lng: 0 }, sw: { lat: 0, lng: 0 } },
    lastSearch: null,
    isCalcZoom: false,
    listingSpots: [],
};

export const map = (state = INITIAL, action) => {
    switch (action.type) {
        case SET_MAP_DATA:
            return { ...state, ...action.value };

        case SET_MARKER_OPEN:
            return { ...state, isMarkerOpen: action.value };

        default:
            return state;
    }
};
