export const JOINED_STATUS = 'joined';
export const KNOWN_STATUS = 'known';
export const CHANNEL_TYPES = {
    SPOT: 'SPOT',
    USER: 'USER',
    DOG: 'DOG',
    DOGS: 'DOGS',
};

export const CLIENT_STATES = {
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
    DISCONNECTING: 'disconnecting',
    DISCONNECTED: 'disconnected',
    DENIED: 'denied',
};
