import {
    CHANGE_SEARCH_FILTERS,
    RESET_SEARCH_FILTERS,
    SET_SEARCH_FILTERS_COUNT,
    SET_SEARCH_FILTERS_REFRESH,
} from '../actions/search-filters.actions';
import { SF_AVAILABILITY_OPTIONS } from '../consts/search-filter.const';
import { getCountSearchFilters, loadFiltersFromStorage } from '../components/search/utils';

export const INITIAL_STATE = {
    filters: {
        showFencing: false,
        fencing: null,
        showSize: false,
        minimumSize: null,
        showDistance: false,
        distance: null,
        dogsPresent: false,
        peoplePresent: false,
        domesticAnimalsPresent: false,
        availability: SF_AVAILABILITY_OPTIONS[0],
        topSpots: false,
        newSpots: false,
        cleanerSpots: false,
        cheaperSpots: false,
        address: '',
    },
    count: 0,
    noRefresh: false,
};

const filtersCookies = loadFiltersFromStorage();

const filtersMerge = {
    ...INITIAL_STATE.filters,
    ...filtersCookies?.filters,
    availability: SF_AVAILABILITY_OPTIONS[0],
};

const initialStateWithCookies = {
    count: getCountSearchFilters(filtersMerge),
    filters: filtersMerge,
};

export const searchFilters = (state = initialStateWithCookies, action) => {
    switch (action.type) {
        case CHANGE_SEARCH_FILTERS:
            return {
                ...state,
                ...{ filters: { ...state.searchFilters, ...action.value.filters } },
            };

        case SET_SEARCH_FILTERS_COUNT:
            return { ...state, count: action.value };

        case SET_SEARCH_FILTERS_REFRESH:
            return { ...state, noRefresh: action.value };

        case RESET_SEARCH_FILTERS:
            return { ...state, ...INITIAL_STATE };
    }
    return state;
};
