import { ADD_REVIEWS_TO_LIST, CLEAR_DATA, SET_SPOT_DATA, SET_SPOT_REVIEWS_DATA } from '../actions/spot.actions';

export const INIT_STATE_SPOT_DATA = {
    id: null,
    instantBook: true,
    title: '',
    listingname: 'Loading...',
    size: null,
    city: null,
    country: null,
    state: null,
    spotPhotos: [],
    photos: [],
    enclosureType: null,
    fenceHeight: null,
    fencingType: null,
    fencingDetail: null,
    gaps: null,
    dogsPresent: null,
    describeDogsPresent: null,
    describeDomesticAnimalsPresent: null,
    describePeoplePresent: null,
    domesticAnimalsPresent: null,
    peoplePresent: null,
    privateEntry: null,
    describePrivateEntry: null,
    isPrivate: false,
    live: false,
    lastReservationAt: null,
    hazards: null,
    description: '',
    latitude: null,
    longitude: null,
    publish: false,

    reviews: [],

    sizeUnits: null,
    street: null,
    rules: null,
    maximumDogsAllowed: null,
    breedSizeRestrictions: null,
    hostPresencePreference: null,

    dogsAmenities: [],
    essentialAmenities: [],
    peopleAmenities: [],
    venueAmenities: [],

    host: {
        email: null,
        firstname: null,
        lastname: null,
        phoneNumber: null,
        avatarAws: null,
        aboutHost: null,
    },

    rating: null,
    reviewsCount: null,
    price: '',

    default_blocks_created: undefined,
    default_blocks_asked: false,
    access_token: null,
    reservationDelayTime: null,
    donateTo: null,
    donatePercent: null,
    turnBackOnAt: null,
    turnOffReason: null,
    tags: [],
    firstTime: null,
    isFavorite: false,
    top: false,
    guestNumber: 0,
    repeatScore: 0,

    dogsAway: false,
    peopleAway: false,
    createdPlatform: 'WEB',
    publishedPlatform: null,
};

export const spot = (state = INIT_STATE_SPOT_DATA, action) => {
    switch (action.type) {
        case SET_SPOT_DATA:
            return {
                ...state,
                ...action.data,
            };

        case ADD_REVIEWS_TO_LIST:
            return {
                ...state,
                reviews: [...state.reviews, ...action.data],
            };

        case SET_SPOT_REVIEWS_DATA:
            return {
                ...state,
                reviews: action.data,
            };

        case CLEAR_DATA:
            return { ...INIT_STATE_SPOT_DATA };
    }
    return state;
};
