import { UPDATE_PAGINATION_METADATA } from '../actions/spot.actions';

const initialState = {
    totalPages: null,
    totalCount: null,
    currentPage: null,
    limitValue: null,
};

export const pagination = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PAGINATION_METADATA:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};
