export const ENABLE_FLAG = 'ENABLE_FLAG';
export const DISABLE_FLAG = 'DISABLE_FLAG';

export function enableFlag(flagName) {
    return {
        type: ENABLE_FLAG,
        payload: flagName
    };
}

export function disableFlag(flagName) {
    return {
        type: DISABLE_FLAG,
        payload: flagName
    };
}
