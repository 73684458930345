import { SET_USER_LOCATION } from '../actions/user.actions';
import { COOKIE_PARAM_NAME } from '../consts/cookies.consts';
import CookieService from '../services/cookie.service';

export const saveUserLocationToCookies = () => (next) => (action) => {
    if (action.type === SET_USER_LOCATION) {
        CookieService.set(
            COOKIE_PARAM_NAME.USER,
            JSON.stringify({
                location: action.value.location,
                userLocationConfirm: action.value.userLocationConfirm,
            }),
            { expires: 30 }
        );
    }
    return next(action);
};
