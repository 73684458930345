import { DISABLE_FLAG, ENABLE_FLAG } from '../actions/flag.actions';

export const initialState = {};

export const flag = (state = initialState, action) => {
    switch (action.type) {
        case ENABLE_FLAG:
            return {
                ...state,
                [action.payload]: true
            };
        case DISABLE_FLAG:
            return {
                ...state,
                [action.payload]: false
            };
        default:
            return state;
    }
};
