import { gql } from '@apollo/client';
import { MSGS_CHANNEL_FRAGMENT } from './messages-fragments';

export const MSGS_CHANNELS_QUERY = gql`
    query channelsList {
        messageToken
        channels {
            ...ChannelInfo
        }
    }
    ${MSGS_CHANNEL_FRAGMENT}
`;

export const MSGS_CREATE_CHANNEL = gql`
    query createChannelWith($id: ID!, $about: About) {
        channelWith(id: $id, about: $about) {
            ...ChannelInfo
        }
    }
    ${MSGS_CHANNEL_FRAGMENT}
`;

export const MSGS_GET_CHANNEL = gql`
    query channel($userId: ID!, $spotId: ID, $create: Boolean) {
        me {
            channel(userId: $userId, spotId: $spotId, create: $create) {
                sid
                token
            }
        }
    }
`;
