import { UPDATE_DOGS_INFO } from '../actions/dogs.actions';

const initialState = {};

export const dogs = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DOGS_INFO:
            return { ...state, ...action.payload.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}) };
        default:
            return state;
    }
};
