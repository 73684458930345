import { gql } from '@apollo/client';
import { ENCLOSURE_TYPE } from '../consts/spot.consts';

export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const CHANGE_OPEN_NOW = 'CHANGE_OPEN_NOW';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_FILTERS_COUNT = 'SET_FILTERS_COUNT';

export const saveFilters =
    (filters) =>
    (_dispatch, _getState, { apolloClient }) => {
        const filtersSpot = JSON.stringify(filters).replace(/"/g, '\\"');

        return apolloClient
            .mutate({
                mutation: gql`
            mutation {
                trackEvent(name: "Search filter" properties:"${filtersSpot}")
            }
        `,
            })
            .catch((err) => {
                console.error(err);
            });
    };

export const setOpenNow = (value) => (dispatch) => {
    dispatch({ type: CHANGE_OPEN_NOW, value });
};

export const setFilters = (value) => (dispatch) => {
    dispatch({ type: CHANGE_FILTERS, value });
};

export const resetFiltersThunk = (value) => (dispatch) => {
    dispatch({ type: RESET_FILTERS, value });
};

export const setFiltersCountThunk = (value) => (dispatch) => {
    dispatch({ type: SET_FILTERS_COUNT, value });
};

export const toggleFencedThunk = () => (dispatch, getState) => {
    const {
        filters: { filters },
    } = getState();
    const { enclosureType } = filters;
    dispatch(
        setFilters({
            filters: {
                ...filters,
                enclosureType: enclosureType === ENCLOSURE_TYPE.FULLY_FENCED ? null : ENCLOSURE_TYPE.FULLY_FENCED,
                changed: true,
            },
        })
    );
};
