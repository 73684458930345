import { UPDATE_SPOT_LIST } from '../actions/spot-list.actions';

const initialState = {
    spotData: {},
};

export function spotList(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SPOT_LIST:
            return {
                ...state,
                spotData: {
                    ...state.spotData,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
