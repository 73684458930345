import {
    ADD_CHANNEL_DESCRIPTOR,
    JOIN_SIMPLE_MESSAGE,
    RESET_MESSAGING,
    SET_CHANNELS_DESCRIPTORS,
    SET_MESSAGING_TOKEN,
    SET_OPENED_DIALOG,
    UPDATE_DIALOG_DATA,
    UPDATE_MESSAGE_LIST,
    UPDATE_MESSAGING,
    UPDATE_SIMPLE_MESSAGE_LIST,
} from '../actions/messaging.actions';
import { CLIENT_STATES } from '../consts/messaging.consts';
import { sortByTimestamp } from '../helpers/time';

export const initialState = {
    clientState: CLIENT_STATES.DISCONNECTED,
    pending: false,
    token: null,
    channelsDescriptors: {},
    dialogData: {},
    messageData: {},
    openedDialog: {
        channelSid: '',
    },
    messageList: [],
};

export const messaging = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CHANNEL_DESCRIPTOR:
            return {
                ...state,
                channelsDescriptors: { ...state.channelsDescriptors, [action.payload.sid]: action.payload },
            };

        case RESET_MESSAGING:
            return { ...initialState, openedDialog: state.openedDialog };

        case SET_MESSAGING_TOKEN:
            return {
                ...state,
                token: action.payload,
            };

        case UPDATE_DIALOG_DATA: {
            const dialogData = state.dialogData;
            return {
                ...state,
                dialogData: {
                    ...dialogData,
                    ...action.payload.reduce((acc, { sid, ...rest }) => (sid ? { ...acc, [sid]: { ...dialogData[sid], sid, ...rest } } : acc), {}),
                },
            };
        }

        case SET_OPENED_DIALOG:
            return {
                ...state,
                openedDialog: {
                    channelSid: action.payload,
                },
            };

        case UPDATE_MESSAGE_LIST: {
            const channelId = action.payload.channelId;
            const messages = action.payload.messages;
            const unchangedMessages = (state.messageData[channelId] || []).filter((msg1) => !messages.some((msg2) => msg1.sid === msg2.sid));

            return {
                ...state,
                messageData: {
                    ...state.messageData,
                    [channelId]: [...unchangedMessages, ...action.payload.messages].sort((msg1, msg2) =>
                        sortByTimestamp(msg1.timestamp, msg2.timestamp)
                    ),
                },
            };
        }

        case SET_CHANNELS_DESCRIPTORS:
            return {
                ...state,
                channelsDescriptors: action.payload.reduce((acc, item) => ({ ...acc, [item.sid]: item }), {}),
            };

        case UPDATE_MESSAGING: {
            const { pending, clientState } = action.payload;
            return {
                ...state,
                ...(pending != null && { pending }),
                ...(clientState != null && { clientState }),
            };
        }

        case UPDATE_SIMPLE_MESSAGE_LIST: {
            const newList = action.payload;

            return {
                ...state,
                messageList: newList,
            };
        }

        case JOIN_SIMPLE_MESSAGE: {
            const sid = action.payload;
            const mIdx = state.messageList.findIndex((m) => m.sid == sid);
            const newList = structuredClone(state.messageList);

            if (mIdx >= 0) {
                newList[mIdx].hasUnreadMessage = false;
            }

            return {
                ...state,
                messageList: newList,
            };
        }

        default:
            return state;
    }
};
