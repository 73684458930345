import { gql } from '@apollo/client';

export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';

const usersQuery = gql`
    query getUsersInfo($ids: [ID]!) {
        users(ids: $ids) {
            id
            firstname
            lastInitial
            avatarAws
        }
    }
`;

const actionUpdateUserList = (payload) => ({
    type: UPDATE_USER_LIST,
    payload,
});

function formatUsers(userList) {
    return userList.reduce((result, user) => {
        result[user.id] = user;
        return result;
    }, {});
}

export function getUserList(idList) {
    return async (dispatch, getState, { apolloClient }) => {
        try {
            if (idList && idList.length) {
                const response = await apolloClient.query({
                    variables: { ids: idList },
                    query: usersQuery,
                });
                if (response.data.users) {
                    const formattedUsers = formatUsers(response.data.users);
                    dispatch(actionUpdateUserList(formattedUsers));
                }
            }
        } catch (e) {
            console.error(e);
        }
    };
}
