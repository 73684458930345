import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';

import { SPOT_GRAPHQL_ERROR } from './spot.error';

import { apiDomain, COOKIE_MODE } from '../consts/env.consts';

import { SUBMIT_REVIEW_BY_GUEST_MUTATION } from '../graphql/review-mutations';
import { ASK_FOR_REVIEW_QUERY } from '../graphql/review-query';

import { createUploadLink } from 'apollo-upload-client/lib';

export const GET_REVIEW_FOR = 'GET_REVIEW_FOR';
export const GET_REVIEW_FOR_SUCCESS = 'GET_REVIEW_FOR_SUCCESS';
export const GET_REVIEW_FOR_ERROR = 'GET_REVIEW_FOR_ERROR';

export const SET_REVIEW_FOR = 'SET_REVIEW_FOR';

export const SAVE_REVIEW = 'SAVE_REVIEW';
export const SAVE_REVIEW_SUCCESS = 'SAVE_REVIEW_SUCCESS';
export const SET_SAVE_REVIEW_DATA = 'SET_SAVE_REVIEW_DATA';
export const SAVE_REVIEW_ERROR = 'SAVE_REVIEW_ERROR';

export const getReviewFor = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_REVIEW_FOR,
        });

        const client = new ApolloClient({
            cache: new InMemoryCache(),
            link: new HttpLink({
                credentials: COOKIE_MODE,
                uri: `${apiDomain}/graphql`,
            }),
        });

        client
            .query({
                variables: { id: id },
                query: ASK_FOR_REVIEW_QUERY,
            })
            .then((resp) => {
                dispatch({
                    type: SET_REVIEW_FOR,
                    data: resp.data.askForReview,
                });

                if (resp.data.askForReview !== null) {
                    dispatch({
                        type: GET_REVIEW_FOR_SUCCESS,
                    });
                } else {
                    dispatch({
                        type: GET_REVIEW_FOR_ERROR,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: GET_REVIEW_FOR_ERROR,
                    value: err,
                });
            });
    };
};

export const saveReview = (review) => {
    return (dispatch, getState, { apolloClient }) => {
        const reviewForSave = { id: review.id };

        Object.keys(review).forEach((key) => {
            reviewForSave[key] = review[key];

            if (key == 'reactive' && review[key] != null && review[key].includes('No')) {
                reviewForSave[key] = null;
            }

            if (key == 'photos') {
                reviewForSave.photos = review.photos.map((p) => {
                    p.file ? (p.imageAws = p.file) : delete p.imageAws;

                    delete p.file;
                    delete p.rotate;
                    delete p.tempId;
                    delete p.warning;
                    return p;
                });
            }
        });

        dispatch({ type: SAVE_REVIEW });
        apolloClient
            .mutate({
                link: new createUploadLink({
                    credentials: COOKIE_MODE,
                    uri: `${apiDomain}/graphql`,
                }),
                variables: reviewForSave,
                mutation: SUBMIT_REVIEW_BY_GUEST_MUTATION,
            })
            .then((resp) => {
                dispatch({
                    type: SET_SAVE_REVIEW_DATA,
                    data: resp.data.submitReview.review,
                });
                if (resp.data.submitReview.errors.length) {
                    dispatch({ type: SAVE_REVIEW_ERROR });
                    dispatch({ type: SPOT_GRAPHQL_ERROR, error: resp.data.submitReview.errors });
                } else {
                    dispatch({ type: SAVE_REVIEW_SUCCESS });
                }
            })
            .catch((err) => {
                console.warn('error', err);
                dispatch({ type: SAVE_REVIEW_ERROR });
            });
    };
};
