import { gql } from '@apollo/client';
import { CHANNEL_TYPES, JOINED_STATUS, KNOWN_STATUS } from '../consts/messaging.consts';
import { MSGS_GET_CHANNEL } from '../graphql/messages-queries';
import { selectDialogBySid, selectLastMessage, selectMessagingToken, selectOpenedDialogId } from '../selectors/messaging.selector';
import { selectSpotData } from '../selectors/spot-list.selector';
import { selectUserId } from '../selectors/user.selector';
import { listDogs } from './dogs.actions';
import { disableFlag, enableFlag } from './flag.actions';
import { getSpotList } from './spot-list.actions';
import { getUserList } from './user-list.actions';

export const SET_MESSAGING_TOKEN = 'SET_MESSAGING_TOKEN';
export const UPDATE_DIALOG_DATA = 'UPDATE_DIALOG_DATA';
export const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST';
export const SET_OPENED_DIALOG = 'SET_OPENED_DIALOG';

export const SET_CHANNELS_DESCRIPTORS = 'SET_CHANNELS_DESCRIPTORS';
export const ADD_CHANNEL_DESCRIPTOR = 'ADD_CHANNEL_DESCRIPTOR';
export const UPDATE_MESSAGING = 'UPDATE_MESSAGING';
export const RESET_MESSAGING = 'RESET_MESSAGING';

export const JOIN_SIMPLE_MESSAGE = 'JOIN_SIMPLE_MESSAGE';
export const UPDATE_SIMPLE_MESSAGE_LIST = 'UPDATE_SIMPLE_MESSAGE_LIST';

// Flags
export const SEND_MESSAGE_LOADING = 'SEND_MESSAGE_LOADING';
export const DIALOG_LIST_LOADING = 'DIALOG_LIST_LOADING';
export const MESSAGE_LIST_LOADING = 'MESSAGE_LIST_LOADING';
export const HAS_MORE_CHANNELS = 'HAS_MORE_CHANNELS';
export const DIALOG_LIST_LOADED = 'DIALOG_LIST_LOADED';
export const MESSAGING_INITIALIZED = 'MESSAGING_INITIALIZED';
export const CREATE_DIALOG_LOADING = 'CREATE_DIALOG_LOADING';

export const actionUpdateDialogData = (payload) => ({ type: UPDATE_DIALOG_DATA, payload });
export const actionUpdateMessageList = (payload) => ({ type: UPDATE_MESSAGE_LIST, payload });
export const actionSetOpenedDialogId = (payload) => ({ type: SET_OPENED_DIALOG, payload });

export const actionJoinSimpleMessage = (payload) => ({ type: JOIN_SIMPLE_MESSAGE, payload });
export const actionUpdateSimpleMessageList = (payload) => ({ type: UPDATE_SIMPLE_MESSAGE_LIST, payload });

const tokenQuery = gql`
    query Token {
        messageToken
    }
`;

function formatMessages(messages) {
    return [...messages].map((item) => item.state);
}

const setMessagingToken = (token) => (dispatch) =>
    dispatch({
        type: SET_MESSAGING_TOKEN,
        payload: token,
    });

export const getMessagingToken = () => {
    return async (dispatch, getState, { apolloClient }) => {
        const {
            user: { data: user },
        } = getState();

        if (user && user.id) {
            try {
                const { data, errors } = await apolloClient.query({
                    query: tokenQuery,
                    fetchPolicy: 'no-cache',
                });
                if (!errors) {
                    dispatch(setMessagingToken(data.messageToken));
                } else {
                    console.warn('geting token errors', errors);
                }
            } catch (err) {
                console.warn('apollo errors', err);
            }
        } else {
            console.warn('user is not authenticated');
            dispatch(setMessagingToken(null));
        }
    };
};

export const reconnect =
    () =>
    async (dispatch, _getState, { chatClient }) => {
        dispatch(disableFlag(MESSAGING_INITIALIZED));
        dispatch({ type: RESET_MESSAGING });
        await chatClient.shutdown();
        dispatch(initMessaging());
    };

export const initMessaging =
    (customToken) =>
    async (dispatch, getState, { chatClient }) => {
        const {
            messaging: { pending },
        } = getState();

        if (!pending) {
            dispatch({ type: UPDATE_MESSAGING, payload: { pending: true } });

            if (!customToken) {
                await dispatch(getMessagingToken());
            }

            const token = customToken || selectMessagingToken(getState());

            if (token) {
                await chatClient.createClient(token);
                // await dispatch(getDialogList());

                chatClient.on('connectionError', ({ terminal, message }) => {
                    console.info('connection error', message);
                    if (terminal) {
                        dispatch(reconnect());
                    }
                });

                // chatClient.on('conversationAdded', (channel) => {
                //     dispatch(initDialogList([channel]));
                // });

                chatClient.on('tokenAboutToExpire', () => dispatch(handleTokenExpired()));
                chatClient.on('connectionStateChanged', (state) => dispatch(connectionStateChanged(state)));
                dispatch(enableFlag(MESSAGING_INITIALIZED));
                dispatch(enableFlag(DIALOG_LIST_LOADED));
            }

            dispatch(connectionStateChanged(chatClient.clientState));
            dispatch({ type: UPDATE_MESSAGING, payload: { pending: false } });
        }
    };

export const connectionStateChanged = (clientState) => async (dispatch) => {
    dispatch({ type: UPDATE_MESSAGING, payload: { clientState } });
    console.info('client state changed to', clientState);
};

function handleTokenExpired() {
    return async (dispatch, getState, { chatClient }) => {
        await dispatch(getMessagingToken());
        const updatedToken = selectMessagingToken(getState());
        await chatClient.updateToken(updatedToken);
    };
}

export function loadMoreMessages(channelId) {
    return async (dispatch, _getState, { chatClient }) => {
        try {
            const channel = await chatClient.getChannelBySid(channelId);
            const paginator = chatClient.getPaginator(channel.sid);
            const newPaginator = await paginator.prevPage();
            chatClient.setPaginator(channelId, newPaginator);

            if (!newPaginator.hasPrevPage) {
                dispatch(actionUpdateDialogData([{ sid: channelId, hasMoreMessages: false }]));
            }
            const formattedMessages = formatMessages(newPaginator.items);
            dispatch(
                actionUpdateMessageList({
                    messages: formattedMessages,
                    channelId: channel.sid,
                    type: UPDATE_MESSAGE_LIST,
                })
            );
        } catch (e) {
            console.warn('getting more messages error', e);
        }
    };
}

function handleAddMessage({ message, channel }) {
    return async (dispatch, getState) => {
        dispatch(
            actionUpdateMessageList({
                messages: [message.state],
                channelId: channel.sid,
                type: UPDATE_MESSAGE_LIST,
            })
        );
        const dialog = selectDialogBySid(getState(), channel.sid);
        const openedDialogId = selectOpenedDialogId(getState());
        const userId = selectUserId(getState());

        const isMyMessage = String(userId) === String(message.state.author);
        let hasUnconsumedMessage;
        if (openedDialogId === channel.sid || isMyMessage) {
            await channel.advanceLastReadMessageIndex(message.state.index);
            hasUnconsumedMessage = false;
        } else {
            hasUnconsumedMessage = true;
        }
        dispatch(actionUpdateDialogData([{ sid: dialog.sid, lastMessage: message, hasUnconsumedMessage }]));
    };
}

export function setAllConsumed(dialogId) {
    return async (dispatch, _getState, { chatClient }) => {
        const channel = await chatClient.getChannelBySid(dialogId);
        await channel.setAllMessagesRead();
        dispatch(actionUpdateDialogData([{ sid: dialogId, hasUnconsumedMessage: false }]));
        dispatch(actionJoinSimpleMessage(dialogId));
    };
}

export function getConversationBySID(dialogId) {
    return async (dispatch, _getState, { chatClient }) => {
        try {
            dispatch(enableFlag(DIALOG_LIST_LOADING));
            const channel = await chatClient.getChannelBySid(dialogId);
            await dispatch(initDialogList([channel]));
        } catch (error) {
            console.warn(error);
        } finally {
            dispatch(disableFlag(DIALOG_LIST_LOADING));
        }
    };
}

export function getConversationByUnique(unique) {
    return async (_dispatch, _getState, { chatClient }) => {
        const channel = await chatClient.getChannelByUnique(unique);
        return channel;
    };
}

function joinToDialog({ channel, info }) {
    return async (dispatch, _getState, { chatClient }) => {
        dispatch(enableFlag(MESSAGE_LIST_LOADING));
        try {
            if (!chatClient.isSubscribed(channel, 'messageAdded')) {
                if (channel.status !== JOINED_STATUS && channel.status !== KNOWN_STATUS) {
                    await channel.join();
                }

                const paginator = await channel.getMessages(15);
                chatClient.setPaginator(channel.sid, paginator);
                dispatch(actionUpdateDialogData([info]));

                const formattedMessages = formatMessages(paginator.items);
                dispatch(
                    actionUpdateMessageList({
                        messages: formattedMessages,
                        channelId: channel.sid,
                        type: UPDATE_MESSAGE_LIST,
                    })
                );

                if (paginator.hasPrevPage) {
                    dispatch(actionUpdateDialogData([{ sid: channel.sid, hasMoreMessages: true }]));
                }
                dispatch(checkUnconsumedMessage(channel));
                chatClient.setSubscribed(channel, 'messageAdded');
                channel.on('messageAdded', (message) => dispatch(handleAddMessage({ message, channel })));
                channel.on('updated', (updated) => dispatch(handleChannelUpdate(updated)));
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(disableFlag(MESSAGE_LIST_LOADING));
        }
    };
}

function checkUnconsumedMessage(channel) {
    return (dispatch, getState) => {
        const dialogId = channel.sid;
        const lastMessage = selectLastMessage(getState(), dialogId);
        const lastMessageIndex = lastMessage && lastMessage.index;
        const lastConsumedIndex = channel.lastReadMessageIndex;

        dispatch(
            actionUpdateDialogData([
                {
                    sid: dialogId,
                    hasUnconsumedMessage: null !== lastMessageIndex && undefined !== lastMessageIndex && lastMessageIndex !== lastConsumedIndex,
                },
            ])
        );
    };
}

function handleChannelUpdate({ updateReasons, channel }) {
    return async (dispatch) => {
        if (Array.isArray(updateReasons)) {
            const consumedIndexChanged = updateReasons.includes('lastConsumedMessageIndex');
            if (consumedIndexChanged) {
                dispatch(checkUnconsumedMessage(channel));
            }
        }
    };
}

export const sendMessage =
    ({ message, sid }) =>
    async (dispatch, _getState, { chatClient }) => {
        const state = chatClient.clientState;
        dispatch(enableFlag(SEND_MESSAGE_LOADING));
        dispatch(connectionStateChanged(state));
        try {
            await chatClient.sendMessageToChannel(sid, message);
            return true;
        } catch (e) {
            console.warn('sending message error', e);
        } finally {
            dispatch(disableFlag(SEND_MESSAGE_LOADING));
        }
        return false;
    };

export const sendMessageBoth =
    ({ message, image, sid }) =>
    async (dispatch, _getState, { chatClient }) => {
        const state = chatClient.clientState;
        dispatch(enableFlag(SEND_MESSAGE_LOADING));
        dispatch(connectionStateChanged(state));
        try {
            await chatClient.sendTextImageToChannel(sid, message, image);
            return true;
        } catch (e) {
            console.warn('sending message error', e);
        } finally {
            dispatch(disableFlag(SEND_MESSAGE_LOADING));
        }
        return false;
    };

function getInterlocutorId(channel) {
    return (_dispatch, getState) => {
        const state = getState();
        const spotData = selectSpotData(state);
        const ownId = String(selectUserId(state));
        const { guest_id, spot_id, users_ids } = channel.attributes; // need to be refactored due to spot_id -> spots_ids[]
        if (users_ids) {
            const [id] = users_ids.filter((item) => String(item) !== ownId);
            return id;
        } else if (String(ownId) === String(guest_id)) {
            const spot = spotData[spot_id];
            return spot && spot.host && spot.host.id;
        } else {
            return guest_id;
        }
    };
}

function getDialogListInfo(dialogList) {
    return async (dispatch) => {
        const spotIdList = dialogList.flatMap(({ attributes: { spots_ids, spot_id } }) => spots_ids || spot_id).filter((id) => !!id);

        await dispatch(getSpotList(spotIdList));

        const dogsIds = dialogList
            .flatMap(({ attributes: { dogs_ids } }) => dogs_ids)
            .filter((id) => !!id)
            .map((id) => String(id));

        await dispatch(listDogs(dogsIds));

        const interlocutorIdList = dialogList.map((channel) => dispatch(getInterlocutorId(channel)));
        await dispatch(getUserList(interlocutorIdList));

        const info = dialogList.reduce((result, dialog) => {
            const {
                attributes: {
                    spot_id,
                    type: channelType = CHANNEL_TYPES.SPOT,
                    users_ids: userIds = [],
                    dogs_ids: dogIds = [],
                    spots_ids: spotIds = [],
                },
                lastMessage,
                dateCreated,
                dateUpdated,
            } = dialog;
            const sid = dialog.sid;
            result[sid] = {
                sid,
                channelType: String(channelType).toUpperCase(),
                attributes: dialog.attributes,
                interlocutorId: dispatch(getInterlocutorId(dialog)),
                spotId: spot_id || spotIds[0],
                dogIds,
                spotIds,
                userIds,
                lastMessage,
                dateCreated,
                dateUpdated,
            };
            return result;
        }, {});

        return info;
    };
}

function initDialogList(dialogList) {
    return async (dispatch) => {
        try {
            const dialogListInfo = await dispatch(getDialogListInfo(dialogList));
            const promiseList = [];
            dialogList.forEach((channel) => {
                promiseList.push(dispatch(joinToDialog({ channel, info: dialogListInfo[channel.sid] })));
            });
            await Promise.all(promiseList);
        } catch (e) {
            console.error(e);
        }
    };
}

export function loadMoreChannels() {
    return async (dispatch, _getState, { chatClient }) => {
        try {
            const paginator = chatClient.getPaginator('channelPaginator');
            await dispatch(initDialogList(paginator.items));

            if (paginator.hasNextPage) {
                chatClient.setPaginator('channelPaginator', await paginator.nextPage());
                await dispatch(loadMoreChannels());
                dispatch(enableFlag(HAS_MORE_CHANNELS));
            } else {
                dispatch(enableFlag(DIALOG_LIST_LOADED));
            }
        } catch (e) {
            console.error(e);
        }
    };
}

export function getDialogList() {
    return async (dispatch, _getState, { chatClient }) => {
        dispatch(enableFlag(DIALOG_LIST_LOADING));
        try {
            chatClient.setPaginator('channelPaginator', await chatClient.getSubscribedChannels());
            await dispatch(loadMoreChannels());
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(disableFlag(DIALOG_LIST_LOADING));
        }
    };
}

export const createChannel =
    ({ id, type = CHANNEL_TYPES.SPOT, spotId = undefined }) =>
    async (dispatch, _getState, { apolloClient }) => {
        dispatch(enableFlag(CREATE_DIALOG_LOADING));
        let res = false;
        try {
            switch (type) {
                case CHANNEL_TYPES.SPOT:
                case CHANNEL_TYPES.DOG:
                    break;

                case CHANNEL_TYPES.USER:
                    console.warn('unsupported channel type');
                    return false;

                default:
                    type = CHANNEL_TYPES.SPOT;
            }
            const variables = { userId: id, spotId, create: true };

            const {
                errors,
                data: {
                    me: { channel },
                },
            } = await apolloClient.query({
                variables,
                query: MSGS_GET_CHANNEL,
            });

            if (!errors) {
                res = channel;
                // dispatch({ type: ADD_CHANNEL_DESCRIPTOR, payload: channelWith });
                // await dispatch(initMessaging(messageToken));
            } else {
                console.warn(errors);
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(disableFlag(CREATE_DIALOG_LOADING));
        }
        return res;
    };

export const sendTypingEvent =
    ({ dialogId }) =>
    async (_dispatch, getState, { chatClient }) => {
        const dialog = selectDialogBySid(getState(), dialogId);
        const channelId = dialog && dialog.sid;
        chatClient.sendTypingEvent(channelId);
    };

export const enableDialogListLoaded = () => (dispatch) => {
    dispatch(enableFlag(DIALOG_LIST_LOADED));
};
