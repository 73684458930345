import { combineReducers } from 'redux';
import { apiState } from './api-state.reducer';
import { app } from './app.reducer';
import { dogs } from './dogs.reducer';
import { favoriteSpots } from './favoriteSpots.reducer';
import { filters } from './filters.reducer';
import { searchFilters } from './search-filters.reducer';
import { flag } from './flag.reducer';
import { listings } from './listings.reducer';
import { map } from './map.reducer';
import { messaging } from './messaging.reducer';
import { reportReducer } from './report.reducer';
import { reservation } from './reservation.reducer';
import { review } from './review.reducer';
import { spotError } from './spot-error.reducer';
import { spotList } from './spot-list.reducer';
import { spot } from './spot.reducer';
import { userList } from './user-list.reducer';
import { user, userDetails } from './user.reducer';
import { viewPage } from './view-page.reducer';
import { pagination } from './pagination.reducer';

export default combineReducers({
    apiState,
    app,
    dogs,
    favoriteSpots,
    filters,
    searchFilters,
    listings,
    spot,
    reservation,
    map,
    user,
    report: reportReducer,
    userDetails,
    viewPage,
    spotError,
    flag,
    review,
    userList,
    spotList,
    messaging,
    pagination,
});
