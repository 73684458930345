import { SEND_REPORT_ERROR, SEND_REPORT_START, SEND_REPORT_SUCCESS } from '../actions/report.actions';

const initialState = {
    isLoading: false,
};
export function reportReducer(state = initialState, action) {
    switch (action.type) {
        case SEND_REPORT_START:
            return {
                ...state,
                isLoading: true,
            };
        case SEND_REPORT_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        case SEND_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
