import { FAVORITE_SPOTS_PENDING, FILTER_FAVORITE_SPOTS, SET_FAVORITE_SPOTS } from '../actions/favoriteSpots.actions';

const INITIAL = {
    favorites: [],
    filtered: [],
    filters: {},
    pending: false,
};

export const favoriteSpots = (state = INITIAL, { type, payload }) => {
    switch (type) {
        case SET_FAVORITE_SPOTS:
            return { ...state, favorites: payload.spots, filtered: payload.spots, filters: payload.filters };

        case FAVORITE_SPOTS_PENDING:
            return { ...state, pending: payload };

        case FILTER_FAVORITE_SPOTS:
            return { ...state, filtered: payload.spots, filters: payload.filters };
    }

    return state;
};
