import { SET_RESERVATION } from '../actions/reservation.actions';

export const INIT_RESERVATION = {
    id: null,
    user: {
        id: null,
        firstname: null,
        lastInitial: null,
        about: null,
    },
    spot: {
        id: null,

        street: null,
        city: null,
        state: null,
        zip: null,
        instructions: null,
        timezone: null,
        title: null,
        host: {},
        spotPhotos: [],
    },
    date: null,
    length: null,
    quantity: null,
    note: null,
    hostNote: null,
    guestNote: null,
    status: null,
};

export const reservation = (state = INIT_RESERVATION, action) => {
    let newState = state;
    switch (action.type) {
        case SET_RESERVATION:
            {
                newState = {
                    ...state,
                    ...action.data,
                };
            }
            break;
    }
    return newState;
};
