import { gql } from '@apollo/client';
import { REVIEW_FRAGMENT } from './review-fragments';

export const SUBMIT_REVIEW_BY_HOST_MUTATION = gql`
    mutation submitReviewByHost($reservationId: ID!, $stars: Int, $comment: String) {
        submitReviewByHost(reservationId: $reservationId, stars: $stars, comment: $comment) {
            ...Review
        }
    }
    ${REVIEW_FRAGMENT}
`;

export const UPDATE_REVIEW_BY_HOST_MUTATION = gql`
    mutation updateReviewByHost($reservationId: ID!, $stars: Int, $comment: String) {
        updateReviewByHost(reservationId: $reservationId, stars: $stars, comment: $comment) {
            ...Review
        }
    }
    ${REVIEW_FRAGMENT}
`;

export const CREATE_REVIEW_BY_GUEST = gql`
    mutation createReviewByGuest($reservationId: ID!, $stars: Int, $createdFrom: CreatedFromReview) {
        createReviewByGuest(reservationId: $reservationId, stars: $stars, createdFrom: $createdFrom) {
            ...Review
        }
    }
    ${REVIEW_FRAGMENT}
`;

export const SUBMIT_REVIEW_BY_GUEST = gql`
    mutation createReviewByGuest($reservationId: ID!) {
        submitReviewByGuest(reservationId: $reservationId) {
            ...Review
        }
    }
    ${REVIEW_FRAGMENT}
`;

export const UPDATE_REVIEW_BY_GUEST = gql`
    mutation updateReviewByGuest(
        $reservationId: ID!
        $stars: Int
        $comment: String
        $photos: [PhotoInputObject!]
        $privateComment: String
        $dogsPresent: Recommendation
        $domesticAnimalsPresent: Recommendation
        $fencingSecureEnough: Recommendation
        $peoplePresent: Recommendation
        $cleanAsExpected: Recommendation
        $swimmingPool: Recommendation
        $lakeOrPond: Recommendation
        $riverStreamOrCreek: Recommendation
        $beach: Recommendation
        $agilityEquipment: Recommendation
        $parkourObstacles: Recommendation
        $trail: Recommendation
        $indoorPlaySpace: Recommendation
        $field: Recommendation
        $smallDogFriendly: Recommendation
        $dogsEncounters: Recommendation
        $dogsEncountersComment: String
        $peopleEncounters: Recommendation
        $peopleEncountersComment: String
        $dogsPresentComment: String
        $peoplePresentComment: String
        $domesticAnimalsPresentComment: String
    ) {
        updateReviewByGuest(
            reservationId: $reservationId
            stars: $stars
            comment: $comment
            photos: $photos
            privateComment: $privateComment
            dogsPresent: $dogsPresent
            domesticAnimalsPresent: $domesticAnimalsPresent
            fencingSecureEnough: $fencingSecureEnough
            peoplePresent: $peoplePresent
            cleanAsExpected: $cleanAsExpected
            swimmingPool: $swimmingPool
            lakeOrPond: $lakeOrPond
            riverStreamOrCreek: $riverStreamOrCreek
            beach: $beach
            agilityEquipment: $agilityEquipment
            parkourObstacles: $parkourObstacles
            trail: $trail
            indoorPlaySpace: $indoorPlaySpace
            field: $field
            smallDogFriendly: $smallDogFriendly
            dogsEncounters: $dogsEncounters
            dogsEncountersComment: $dogsEncountersComment
            peopleEncounters: $peopleEncounters
            peopleEncountersComment: $peopleEncountersComment
            dogsPresentComment: $dogsPresentComment
            peoplePresentComment: $peoplePresentComment
            domesticAnimalsPresentComment: $domesticAnimalsPresentComment
        ) {
            ...Review
        }
    }
    ${REVIEW_FRAGMENT}
`;

export const SUBMIT_REVIEW_BY_GUEST_MUTATION = gql`
    mutation submitReviewByGuest(
        $reservationId: ID!
        $stars: Int
        $comment: String
        $photos: [PhotoInputObject!]
        $reactive: [String]
        $reactiveRecommend: String
        $reactiveComment: String
        $privateComment: String
        $valueForMoney: Int
    ) {
        submitReviewByGuest(
            reservationId: $reservationId
            stars: $stars
            comment: $comment
            photos: $photos
            reactive: $reactive
            reactiveRecommend: $reactiveRecommend
            reactiveComment: $reactiveComment
            privateComment: $privateComment
            valueForMoney: $valueForMoney
        ) {
            ...Review
        }
    }
    ${REVIEW_FRAGMENT}
`;

export const SUBMIT_UPVOTE_REVIEW = gql`
    mutation submitUpvoteReview($id: ID!) {
        upvote(id: $id) {
            id
        }
    }
`;

export const RECEIVE_REVIEWS = gql`
    mutation receiveReview($ids: [ID!]!) {
        receiveReview(ids: $ids) {
            id
        }
    }
`;
