import {
    GET_MORE_COMMENTS,
    GET_MORE_COMMENTS_ERROR,
    GET_MORE_COMMENTS_SUCCESS,
    GET_SPOT_INFO,
    GET_SPOT_INFO_ERROR,
    GET_SPOT_INFO_SUCCESS,
    SUBSCRIBE_FOR_UPDATE,
    SUBSCRIBE_FOR_UPDATE_ERROR,
    SUBSCRIBE_FOR_UPDATE_SUCCESS,
    UPDATE_SPOT,
    UPDATE_SPOT_ERROR,
    UPDATE_SPOT_SUCCESS,
} from '../actions/spot.actions';

import {
    CANCEL_RESERVATION,
    CANCEL_RESERVATION_ERROR,
    CANCEL_RESERVATION_SUCCESS,
    CONFIRM_RESERVATION,
    CONFIRM_RESERVATION_ERROR,
    CONFIRM_RESERVATION_SUCCESS,
    DECLINE_RESERVATION,
    DECLINE_RESERVATION_ERROR,
    DECLINE_RESERVATION_SUCCESS,
    GET_RESERVATION,
    GET_RESERVATION_ERROR,
    GET_RESERVATION_SUCCESS,
    UPDATE_RESERVATION_SPOT,
    UPDATE_RESERVATION_SPOT_ERROR,
    UPDATE_RESERVATION_SPOT_IDLE,
    UPDATE_RESERVATION_SPOT_SUCCESS,
} from '../actions/reservation.actions';

import {
    GET_USER_DETAILS,
    GET_USER_DETAILS_ERROR,
    GET_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS,
    UPDATE_USER_DETAILS_ERROR,
    UPDATE_USER_DETAILS_SUCCESS,
} from '../actions/user.actions';

import {
    GET_REVIEW_FOR,
    GET_REVIEW_FOR_ERROR,
    GET_REVIEW_FOR_SUCCESS,
    SAVE_REVIEW,
    SAVE_REVIEW_ERROR,
    SAVE_REVIEW_SUCCESS,
} from '../actions/review.actions';

export const INIT_STATE_API_STATE = {
    getSpotInfo: {
        wait: false,
        success: false,
    },
    updateSpotInfo: {
        wait: false,
        success: false,
        error: false,
    },
    getMoreComments: {
        wait: false,
        success: false,
    },
    subscribeForUpdate: {
        wait: false,
        success: false,
    },
    getReservationState: {
        wait: false,
        success: false,
    },
    submitReservationState: {
        wait: false,
        success: false,
    },
    declineReservationState: {
        wait: false,
        success: false,
    },
    cancelReservationState: {
        wait: false,
        success: false,
    },
    updateReservationSpotState: {
        wait: false,
        success: false,
    },
    getUserDetailsState: {
        wait: false,
        success: false,
    },
    updateUserDetailsState: {
        wait: false,
        success: false,
    },
    getReviewForState: {
        wait: false,
        success: false,
    },
    saveReviewState: {
        wait: false,
        success: false,
    },
};

export const apiState = (state = INIT_STATE_API_STATE, action) => {
    switch (action.type) {
        case GET_MORE_COMMENTS:
            return {
                ...state,
                getMoreComments: {
                    wait: true,
                    success: false,
                },
            };
        case GET_MORE_COMMENTS_SUCCESS:
            return {
                ...state,
                getMoreComments: {
                    wait: false,
                    success: true,
                },
            };
        case GET_MORE_COMMENTS_ERROR:
            return {
                ...state,
                getMoreComments: {
                    wait: false,
                    success: false,
                },
            };
        case GET_SPOT_INFO:
            return {
                ...state,
                getSpotInfo: {
                    wait: true,
                    success: false,
                },
            };
        case GET_SPOT_INFO_SUCCESS:
            return {
                ...state,
                getSpotInfo: {
                    wait: false,
                    success: true,
                },
            };
        case GET_SPOT_INFO_ERROR:
            return {
                ...state,
                getSpotInfo: {
                    wait: false,
                    success: false,
                },
            };
        case UPDATE_SPOT:
            return {
                ...state,
                updateSpotInfo: {
                    wait: true,
                    success: false,
                },
            };
        case UPDATE_SPOT_SUCCESS:
            return {
                ...state,
                updateSpotInfo: {
                    wait: false,
                    success: true,
                    error: false,
                },
            };
        case UPDATE_SPOT_ERROR:
            return {
                ...state,
                updateSpotInfo: {
                    wait: false,
                    success: false,
                    error: true,
                },
            };
        case SUBSCRIBE_FOR_UPDATE:
            return {
                ...state,
                subscribeForUpdate: {
                    wait: true,
                    success: false,
                },
            };
        case SUBSCRIBE_FOR_UPDATE_SUCCESS:
            return {
                ...state,
                subscribeForUpdate: {
                    wait: false,
                    success: true,
                },
            };
        case SUBSCRIBE_FOR_UPDATE_ERROR:
            return {
                ...state,
                subscribeForUpdate: {
                    wait: false,
                    success: false,
                },
            };
        case GET_RESERVATION:
            return {
                ...state,
                getReservationState: {
                    wait: true,
                    success: false,
                },
            };
        case GET_RESERVATION_SUCCESS:
            return {
                ...state,
                getReservationState: {
                    wait: false,
                    success: true,
                },
            };
        case GET_RESERVATION_ERROR:
            return {
                ...state,
                getReservationState: {
                    wait: false,
                    success: false,
                },
            };
        case CONFIRM_RESERVATION:
            return {
                ...state,
                submitReservationState: {
                    wait: true,
                    success: false,
                },
            };
        case CONFIRM_RESERVATION_SUCCESS:
            return {
                ...state,
                submitReservationState: {
                    wait: false,
                    success: true,
                },
            };
        case CONFIRM_RESERVATION_ERROR:
            return {
                ...state,
                submitReservationState: {
                    wait: false,
                    success: false,
                },
            };
        case DECLINE_RESERVATION:
            return {
                ...state,
                declineReservationState: {
                    wait: true,
                    success: false,
                },
            };
        case DECLINE_RESERVATION_SUCCESS:
            return {
                ...state,
                declineReservationState: {
                    wait: false,
                    success: true,
                },
            };
        case DECLINE_RESERVATION_ERROR:
            return {
                ...state,
                declineReservationState: {
                    wait: false,
                    success: false,
                },
            };
        case CANCEL_RESERVATION:
            return {
                ...state,
                cancelReservationState: {
                    wait: true,
                    success: false,
                },
            };
        case CANCEL_RESERVATION_SUCCESS:
            return {
                ...state,
                cancelReservationState: {
                    wait: false,
                    success: true,
                },
            };
        case CANCEL_RESERVATION_ERROR:
            return {
                ...state,
                cancelReservationState: {
                    wait: false,
                    success: false,
                },
            };
        case UPDATE_RESERVATION_SPOT:
            return {
                ...state,
                updateReservationSpotState: {
                    wait: true,
                    success: false,
                },
            };
        case UPDATE_RESERVATION_SPOT_IDLE:
            return {
                ...state,
                updateReservationSpotState: {
                    wait: false,
                    success: false,
                },
            };
        case UPDATE_RESERVATION_SPOT_SUCCESS:
            return {
                ...state,
                updateReservationSpotState: {
                    wait: false,
                    success: true,
                },
            };
        case UPDATE_RESERVATION_SPOT_ERROR:
            return {
                ...state,
                updateReservationSpotState: {
                    wait: false,
                    success: false,
                },
            };
        case GET_USER_DETAILS:
            return {
                ...state,
                getUserDetailsState: {
                    wait: true,
                    success: false,
                },
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                getUserDetailsState: {
                    wait: false,
                    success: true,
                },
            };
        case GET_USER_DETAILS_ERROR:
            return {
                ...state,
                getUserDetailsState: {
                    wait: false,
                    success: false,
                },
            };
        case UPDATE_USER_DETAILS:
            return {
                ...state,
                updateUserDetailsState: {
                    wait: true,
                    success: false,
                },
            };
        case UPDATE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                updateUserDetailsState: {
                    wait: false,
                    success: true,
                },
            };
        case UPDATE_USER_DETAILS_ERROR:
            return {
                ...state,
                updateUserDetailsState: {
                    wait: false,
                    success: false,
                },
            };
        case GET_REVIEW_FOR:
            return {
                ...state,
                getReviewForState: {
                    wait: true,
                    success: false,
                },
            };
        case GET_REVIEW_FOR_SUCCESS:
            return {
                ...state,
                getReviewForState: {
                    wait: false,
                    success: true,
                },
            };
        case GET_REVIEW_FOR_ERROR:
            return {
                ...state,
                getReviewForState: {
                    wait: false,
                    success: false,
                },
            };
        case SAVE_REVIEW:
            return {
                ...state,
                saveReviewState: {
                    wait: true,
                    success: false,
                },
            };
        case SAVE_REVIEW_SUCCESS:
            return {
                ...state,
                saveReviewState: {
                    wait: false,
                    success: true,
                },
            };
        case SAVE_REVIEW_ERROR:
            return {
                ...state,
                saveReviewState: {
                    wait: false,
                    success: false,
                },
            };
    }
    return state;
};
