import { createSelector } from 'reselect';
import { sortByTimestamp } from '../helpers/time';
import { selectSpot } from './spot-list.selector';
import { selectUser } from './user-list.selector';
import { selectIsUserOwnSpot, selectSpotCount } from './user.selector';

function selectMessagingState(state) {
    return state.messaging;
}

export function selectMessagingToken(state) {
    return selectMessagingState(state).token;
}

export const selectClientState = createSelector(selectMessagingState, ({ clientState }) => clientState);

export function selectDialogData(state) {
    return selectMessagingState(state).dialogData;
}

export const selectDialogList = createSelector(selectDialogData, (dialogData) => Object.values(dialogData));

export function selectMessageData(state) {
    return selectMessagingState(state).messageData;
}

export function selectDialogBySid(state, id) {
    return selectDialogData(state)[id];
}

export function selectHasMoreMessages(state, dialogId) {
    const dialog = selectDialogBySid(state, dialogId);
    return dialog && dialog.hasMoreMessages;
}

export function selectChannelsBySpotId(state, spotId) {
    const dialogList = selectDialogList(state);

    const spotIdStr = String(spotId);

    const filter = (dialog) =>
        (dialog?.spotIds && dialog?.spotIds?.some((id) => String(id) === spotIdStr)) || String(dialog?.attributes?.spot_id) === spotIdStr;

    const channels = dialogList?.filter(filter).sort((a, b) => a?.dateCreated - b?.dateCreated);
    return channels;
}

export function selectDialogIdBySpotId(state, spotId) {
    const channels = selectChannelsBySpotId(state, spotId);

    return channels && channels[0] && channels[0].sid;
}

export function selectChannelIdByGuestAndSpot(state, guestId, spotId) {
    let channels = [];

    if (spotId) {
        channels = selectChannelsBySpotId(state, spotId).filter((item) => String(item.interlocutorId) === String(guestId));
    } else {
        const dialogList = selectDialogList(state);
        channels = dialogList.filter((item) => String(item.interlocutorId) === String(guestId));
    }

    return channels && channels[0] && channels[0].sid;
}

export function selectDialogSpot(state, dialogId) {
    const dialog = selectDialogBySid(state, dialogId);
    const spotId = dialog && dialog.spotId;
    return selectSpot(state, spotId);
}

export function selectIsDialogToGuest(state, dialogId) {
    const dialog = selectDialogBySid(state, dialogId);
    return dialog && selectIsUserOwnSpot(state, dialog.spotId);
}

function selectNonFilteredMessageList(state, dialogId) {
    return selectMessageData(state)[dialogId];
}

export function selectDialogInterlocutor(state, id) {
    const dialog = selectDialogBySid(state, id);
    const interlocutorId = dialog && dialog.interlocutorId;
    return selectUser(state, interlocutorId);
}

export const selectMessageList = createSelector(selectNonFilteredMessageList, (messageList) => {
    if (!messageList) {
        return [];
    }

    return [...messageList].sort((message, nextMessage) => {
        return sortByTimestamp(message.timestamp, nextMessage.timestamp);
    });
});

export const selectLastMessage = createSelector(selectMessageList, (messageList) => messageList[0]);

export const selectOpenedDialogId = (state) => {
    return selectMessagingState(state).openedDialog.channelSid;
};

export const selectHasUnconsumedMessageByChannel = (state, dialogId) => {
    const dialog = selectDialogBySid(state, dialogId);
    return dialog && dialog.hasUnconsumedMessage;
};

export const selectHasUnconsumedMessage = createSelector(selectDialogList, (dialogList) => dialogList.filter((item) => item.hasUnconsumedMessage));

export const selectGuestDialogList = createSelector(
    selectDialogList,
    (state) => state,
    (dialogList, state) =>
        [...dialogList]
            .filter((dialog) => dialog.lastMessage && !selectIsDialogToGuest(state, dialog.sid))
            .sort((dialog, nextDialog) => sortByTimestamp(dialog.lastMessage.dateCreated, nextDialog.lastMessage.dateCreated))
);

const selectFullHostDialogList = createSelector(
    selectDialogList,
    (state) => state,
    (dialogList, state) => {
        return dialogList.filter((dialog) => selectIsDialogToGuest(state, dialog.sid));
    }
);

export const selectHostDialogList = createSelector(selectFullHostDialogList, (dialogList) => {
    return [...dialogList]
        .filter((dialog) => dialog.lastMessage)
        .sort((dialog, nextDialog) => sortByTimestamp(dialog.lastMessage.dateCreated, nextDialog.lastMessage.dateCreated));
});

export const selectDialogIdByGuestId = createSelector(
    selectFullHostDialogList,
    selectSpotCount,
    (state, guestId) => guestId,
    (state, guestId, spotId) => spotId,
    (dialogList, spotCount, guestId, spotId) => {
        let dialog = null;
        if (spotCount > 1) {
            if (spotId) {
                dialog = dialogList.find(
                    (dialog) => String(dialog.interlocutorId) === String(guestId) && dialog.spotIds.some((id) => String(id) === String(spotId))
                );
            }
        } else {
            dialog = dialogList.find((dialog) => String(dialog.interlocutorId) === String(guestId));
        }
        return dialog && dialog.sid;
    }
);
