import { SET_HOST_TOP, SET_OVERVIEW_TOP, SET_REVIEWS_TOP } from '../actions/view-page.actions';

const VIEW_PAGE_INIT = {
    hostTop: null,
    overviewTop: null,
    reviewTop: null
};

export const viewPage = (state = VIEW_PAGE_INIT, action) => {
    switch (action.type) {
        case SET_HOST_TOP:
            return {
                ...state,
                hostTop: action.data
            };
        case SET_OVERVIEW_TOP:
            return {
                ...state,
                overviewTop: action.data
            };
        case SET_REVIEWS_TOP:
            return {
                ...state,
                reviewTop: action.data
            };
    }
    return state;
};
