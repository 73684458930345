import {
    SET_ACTIVE_LISTINGS_ITEM,
    SET_CURRENT_PAGE,
    SET_FILTERED_SPOTS,
    SET_INACTIVE_LISTINGS_ITEM,
    SET_LOADING,
    SET_SPOTS_LIST,
    SET_SPOTS_LIST_ERROR,
    SET_SPOTS_LIST_LOADING,
    SET_SPOTS_LIST_METADATA,
    SET_SPOTS_LIST_SUCCESS,
} from '../actions/spot.actions';

const LISTINGS_INITIAL = {
    filtered: [],
    currentPage: 1,
    items: [],
    loading: true,
    errors: [],
    requestTs: 0, // need for getting recent query result
    activeItemId: null,
    metadata: {},
};

export const listings = (state = LISTINGS_INITIAL, action) => {
    switch (action.type) {
        case SET_SPOTS_LIST:
            return {
                ...state,
                requestTs: action.requestTs,
                loading: true,
            };

        case SET_SPOTS_LIST_SUCCESS:
            if (action.requestTs >= state.requestTs) {
                return {
                    ...state,
                    items: action.data,
                    currentPage: 1,
                    loading: false,
                };
            }
            break;

        case SET_SPOTS_LIST_ERROR:
            if (action.requestTs >= state.requestTs) {
                return {
                    ...state,
                    items: [],
                    currentPage: 1,
                    errors: action.data,
                    loading: false,
                };
            }
            break;

        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.currentPage,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: action.value,
            };

        case SET_SPOTS_LIST_METADATA:
            return {
                ...state,
                metadata: action.value,
            };

        case SET_ACTIVE_LISTINGS_ITEM: {
            return {
                ...state,
                activeItemId: action.id,
            };
        }

        case SET_INACTIVE_LISTINGS_ITEM: {
            return {
                ...state,
                activeItemId: null,
            };
        }

        case SET_FILTERED_SPOTS:
            return {
                ...state,
                filtered: action.value,
                currentPage: 1,
            };

        case SET_SPOTS_LIST_LOADING:
            return {
                ...state,
                loading: action.value,
            };
    }
    return state;
};
