import { CHANGE_FILTERS, CHANGE_OPEN_NOW, RESET_FILTERS, SET_FILTERS_COUNT } from '../actions/filters.actions';
import { calcCountFilters, loadFiltersFromStorage } from '../components/search/utils';

export const INITIAL_STATE = {
    filters: {
        openNow: false,
        availability: {},
        instantBook: false,
        enclosureType: '',
        fenceHeight: '',
        tags: [],
        size: {},
        price: null,
        dogsPresent: true,
        peoplePresent: true,
        domesticAnimalsPresent: true,
        privateEntry: false,
    },
    count: 0,
    changed: false,
};

let filtersCookies = loadFiltersFromStorage();

const filtersMerge = {
    ...INITIAL_STATE.filters,
    ...filtersCookies.filters,
};

const initialStateWithCookies = {
    count: calcCountFilters(filtersMerge),
    filters: filtersMerge,
    changed: false,
};

export const filters = (state = initialStateWithCookies, action) => {
    switch (action.type) {
        case CHANGE_FILTERS:
            return {
                ...state,
                ...{ filters: { ...state.filters, ...action.value.filters }, changed: action.value.changed },
            };

        case CHANGE_OPEN_NOW:
            return {
                ...state,
                ...{ filters: { ...state.filters, openNow: action.value }, changed: true },
            };

        case SET_FILTERS_COUNT:
            return { ...state, count: action.value };

        case RESET_FILTERS:
            return {
                ...state,
                ...action.value,
            };
    }
    return state;
};
