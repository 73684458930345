import { gql } from '@apollo/client';
import { disableFlag, enableFlag } from './flag.actions';

export const UPDATE_SPOT_LIST = 'UPDATE_SPOT_LIST';
export const SPOT_LIST_LOADING = 'SPOT_LIST_LOADING';

export const SpotGql = {
    fragments: {
        shortInfo: gql`
            fragment ShortSpot on Spot {
                id
                title
                rating
                state
                city
                allPhotos
                host {
                    id
                }
            }
        `,
        fullSpot: gql`
            fragment Spot on Spot {
                id
                instantBook
                title
                listingname
                size
                city
                country
                state
                key
                photos {
                    id
                    imageAws
                    ordinal
                }
                enclosureType
                fenceHeight
                gaps
                dogsPresent
                describeDogsPresent
                describeDomesticAnimalsPresent
                describePeoplePresent
                domesticAnimalsPresent
                peoplePresent
                isPrivate
                privateEntry
                describePrivateEntry
                hazards
                description
                latitude
                longitude
                live
                reviews(first: 3) {
                    id
                    comment
                    createdAt
                    reactive
                    stars
                    user {
                        id
                        firstname
                        lastInitial
                        avatarAws
                    }
                    reactiveComment
                    reactiveRecommend
                }
                responseTime
                acceptanceRate
                breedSizeRestrictions
                sizeUnits
                street
                zip
                instructions
                rules
                maximumDogsAllowed
                breedSizeRestrictions
                hostPresencePreference
                host {
                    email
                    firstname
                    lastInitial
                    phoneNumber
                    avatarAws
                    aboutHost
                }
                rating
                reviewsCount
                price
                live
                timezone
                reservationDelayTime
                donateTo
                donatePercent
                tags
                top
            }
        `,
    },
};

const spotDetailsMsgQuery = gql`
    query spot($id: ID!) {
        spot(id: $id) {
            id
            title
            rating
            state
            city
            host {
                id
            }
        }
    }
`;

const fullSpotQuery = gql`
    query getSpotList($ids: [ID]!) {
        spots(ids: $ids) {
            ...ShortSpot
        }
    }
    ${SpotGql.fragments.shortInfo}
`;

function formatSpots(spotList) {
    return spotList.reduce((result, spot) => {
        result[spot.id] = spot;
        return result;
    }, {});
}

const actionUpdateSpotList = (payload) => ({
    type: UPDATE_SPOT_LIST,
    payload,
});

// action for getting short info about spots
export function getSpotList(idList) {
    return async (dispatch, _getState, { apolloClient }) => {
        try {
            if (idList && idList.length) {
                const resAll = await Promise.allSettled(
                    idList.map((id) =>
                        apolloClient.query({
                            variables: { id },
                            query: spotDetailsMsgQuery,
                        })
                    )
                );

                const list = resAll.filter((r) => r.status == 'fulfilled');

                if (list.length > 0) {
                    const spots = list.map((r) => r.value.data.spot);
                    const formattedSpotList = formatSpots(spots);
                    dispatch(actionUpdateSpotList(formattedSpotList));
                }
            }
        } catch (e) {
            console.error(e);
        }
    };
}

export function getFullSpot(spotId) {
    return async (dispatch, getState, { apolloClient }) => {
        dispatch(enableFlag(SPOT_LIST_LOADING));
        try {
            if (spotId) {
                const { data } = await apolloClient.query({
                    variables: { ids: [spotId] },
                    query: fullSpotQuery,
                });

                if (data.spots) {
                    const fullyLoaded = data.spots.map((spot) => ({
                        ...spot,
                        fullyLoaded: true,
                    }));
                    const formattedSpotList = formatSpots(fullyLoaded);
                    dispatch(actionUpdateSpotList(formattedSpotList));
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(disableFlag(SPOT_LIST_LOADING));
        }
    };
}
