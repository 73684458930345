import { gql } from '@apollo/client';

export const ASK_FOR_REVIEW_QUERY = gql`
    query AskForReview($id: ID!) {
        askForReview(id: $id) {
            id
            spot {
                id
                title
            }
        }
    }
`;

export const RECENT_REVIEWS_QUERY = gql`
    query reviews($region: SeoRegion) {
        recentReviews(region: $region) {
            id
            stars
            comment
            submittedAt
            providedBy {
                id
                avatarAws
                nameAndInitial
            }
        }
    }
`;
