import {
    GET_USER_INFO,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_SUCCESS,
    SET_ALERT_DATA,
    SET_USER_DETAILS,
    SET_USER_LOCATION,
} from '../actions/user.actions';

const INITiAL = {
    location: {},
    userLocationConfirm: false,
    data: {},
    errors: null,
    loading: false,
};

export const user = (state = INITiAL, action) => {
    switch (action.type) {
        case SET_USER_LOCATION:
            return {
                ...state,
                location: action.value.location,
                userLocationConfirm:
                    typeof action.value.userLocationConfirm === 'undefined'
                        ? state.userLocationConfirm || undefined
                        : action.value.userLocationConfirm,
            };
        case GET_USER_INFO:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                data: action.value,
                loading: false,
            };
        case GET_USER_INFO_ERROR:
            return {
                ...state,
                errors: action.value,
                loading: false,
            };
    }
    return state;
};

export const INITIAL_ALERT = {
    radius: 50,
    latitude: NaN,
    longitude: NaN,
    enclosureType: undefined,
    minimumSize: undefined,
    active: false,
    dogsAllowed: true,
};

const INITIAL_CREDITS = {
    available: 0,
    inviteTotal: 0,
    total: 0,
};

export const INIT_USER = {
    id: null,
    firstname: 'New',
    lastname: 'Host',
    lastInitial: null,
    about: null,
    aboutHost: '',
    aboutHostMore: '',
    tosAgree: false,
    vaccinated: false,
    phoneNumber: null,
    changeRate: null,
    email: '',
    firstHear: null,
    avatarAws: '',
    hasUpcomingReservations: true,
    spots: [],
    dogs: [],
    spotAlert: { ...INITIAL_ALERT },
    credits: { ...INITIAL_CREDITS },
    instagramUsername: '',
};

export const userDetails = (state = INIT_USER, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                ...action.data,
            };

        case SET_ALERT_DATA:
            return {
                ...state,
                spotAlert: {
                    ...state.spotAlert,
                    ...action.payload,
                },
            };
    }
    return state;
};
