import { SPOT_GRAPHQL_ERROR } from '../actions/spot.error';

export const INIT_SPOT_GRAPHQL_ERROR = {
    error: [],
};

export const spotError = (state = INIT_SPOT_GRAPHQL_ERROR, { type, error }) => {
    switch (type) {
        case SPOT_GRAPHQL_ERROR:
            console.info(SPOT_GRAPHQL_ERROR, error);
            return { ...state, error };
        default:
            return state;
    }
};
