export const SHOW_SUBSCRIBE_MODAL = 'SHOW_SUBSCRIBE_MODAL';
export const SEARCH_LISTINGS_FOCUS = 'SEARCH_LISTINGS_FOCUS';

// Close and save to cookie
export const SMART_BANNER_CLOSE = 'SMART_BANNER_CLOSE';
export const SMART_BANNER_SHOW = 'SMART_BANNER_SHOW';

export const SET_ALL_SET = 'SET_ALL_SET';

export const setSearchFocus = (value) => (dispatch) => {
    dispatch({ type: SEARCH_LISTINGS_FOCUS, value });
};
