function selectSpotListState(state) {
    return state.spotList;
}

export function selectSpotData(state) {
    return selectSpotListState(state).spotData;
}

export function selectSpot(state, id) {
    return selectSpotData(state)[id];
}

export function selectSpotFullyLoaded(state, id) {
    const spot = selectSpot(state, id);
    return spot && spot.fullyLoaded;
}

export function selectHasPublishedSpots(state) {
    return (
        state &&
        state.userDetails &&
        Array.isArray(state.userDetails.spots) &&
        state.userDetails.spots.some((spot) => Boolean(spot.publishedAt))
    );
}
