import { CHANGE_FILTERS } from '../actions/filters.actions';
import { CHANGE_SEARCH_FILTERS, RESET_SEARCH_FILTERS } from '../actions/search-filters.actions';
import { FILTER_FAVORITE_SPOTS } from '../actions/favoriteSpots.actions';
import { COOKIE_PARAM_NAME } from '../consts/cookies.consts';
import CookieService from '../services/cookie.service';
import { omit } from '../utils';

const exclude = ['availability', 'openNow', 'availableDates', 'availableTimes'];

export const filtersSaveToCookie = (store) => (next) => (action) => {
    if (action.type === CHANGE_FILTERS) {
        let filters = action.value.filters;
        let count = store.getState().filters.count;
        if (filters?.availability && filters?.availability.startDate) {
            count--;
        }
        filters = omit(filters, exclude);
        CookieService.set(COOKIE_PARAM_NAME.FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        filters.availability &&
            CookieService.set(COOKIE_PARAM_NAME.SPOT_DATA_FILTER, JSON.stringify(filters.availability), {
                expires: 0,
            });
    }

    if (action.type === CHANGE_SEARCH_FILTERS) {
        if (action.value.shallow) {
            return next(action);
        }
        let filters = action.value.filters;
        let count = store.getState().searchFilters.count;
        const availableDates = filters?.availableDates;
        const availableTimes = filters?.availableTimes;
        const availableFilters = { availableDates, availableTimes };
        filters = omit(filters, exclude);
        CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        if (availableDates || availableTimes) {
            CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS_AVAILABILITY, JSON.stringify(availableFilters), {
                expires: 0,
            });
        } else {
            CookieService.erase(COOKIE_PARAM_NAME.SEARCH_FILTERS_AVAILABILITY);
        }
    }

    if (action.type === FILTER_FAVORITE_SPOTS) {
        const { filters } = action.payload;
        CookieService.set(COOKIE_PARAM_NAME.SPOT_DATA_FILTER, JSON.stringify(filters), { expires: 365 });
    }

    if (action.type === RESET_SEARCH_FILTERS) {
        CookieService.erase(COOKIE_PARAM_NAME.SEARCH_FILTERS);
        CookieService.erase(COOKIE_PARAM_NAME.SEARCH_FILTERS_AVAILABILITY);
        CookieService.erase(COOKIE_PARAM_NAME.SPOT_DATA_FILTER);
    }

    return next(action);
};
