import { createSelector } from 'reselect';
import { isUnverified } from '../consts/user.consts';

function selectUserState(state) {
    return state.user;
}

// Small piece of information about user
export function selectUserInfo(state) {
    return selectUserState(state).data;
}

export function selectBlockedUserIds(state) {
    return state.userDetails?.blockedUsers?.map((u) => parseInt(u?.id));
}

export function selectUserUnver(state) {
    return isUnverified(state.userDetails);
}

// Main part of information about user
export function selectUserDetailsState(state) {
    return state.userDetails;
}

export function selectUserInfoLoading(state) {
    return selectUserState(state).loading;
}

export function selectIsHost(state) {
    const spots = selectUserDetailsState(state).spots;
    return Boolean(spots && spots.length);
}

export function selectUserId(state) {
    return selectUserInfo(state).id;
}

export function selectIsUserOwnSpot(state, spotId) {
    const userDetails = selectUserDetailsState(state);
    return userDetails.spots.some((spot) => String(spot.id) === String(spotId));
}

export function selectHostSpots(state) {
    return selectUserDetailsState(state).spots;
}

export const selectHostCompletedSpots = createSelector(selectHostSpots, (spots) => spots.filter((spot) => spot.photos.length));

export function selectSpotCount(state) {
    const userDetails = selectUserDetailsState(state);
    return userDetails.spots.length;
}

export function selectUserLocation(state) {
    return selectUserState(state).location;
}

export function selectHasUpcomingReservations(state) {
    return selectUserDetailsState(state).hasUpcomingReservations;
}

export function selectTotalEarnings(state) {
    return selectUserDetailsState(state).totalEarnings;
}

export function selectHasMultipleSpots(state) {
    return selectSpotCount(state) > 1;
}

export function selectIsFullUserInfoLoading(state) {
    return state.apiState.getUserDetailsState.wait;
}

export function selectIsFullUserInfoLoaded(state) {
    return state.apiState.getUserDetailsState.success;
}

export function selectUserLocationConfirmed(state) {
    return selectUserState(state).userLocationConfirm;
}

export const selectIsAuthenticated = createSelector(selectUserInfo, (user) => !!(user && user.id));
