import { SET_REVIEW_FOR, SET_SAVE_REVIEW_DATA } from '../actions/review.actions';

export const INIT_REVIEW = {
    id: null,
    spot: {
        reviews: [],
        host: {
            sscid: null,
        },
    },
    title: '',
    reactive: [],
};

export const review = (state = INIT_REVIEW, action) => {
    let newState = state;
    switch (action.type) {
        case SET_REVIEW_FOR:
            {
                newState = {
                    ...state,
                    ...action.data,
                };
            }
            break;
        case SET_SAVE_REVIEW_DATA: {
            newState = {
                ...state,
                ...action.data,
            };
            break;
        }
    }
    return newState;
};
