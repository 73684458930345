import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { filtersSaveToCookie } from './middlewares/filtersSaveToCookie';
import { saveUserLocationToCookies } from './middlewares/saveUserLocationToCookies';
import rootReducer from './reducers';
import { INITIAL as MAP_INITIAL } from './reducers/map.reducer';
import CookieService from './services/cookie.service';
import { apolloClient } from './services/graphql.service';
import TwilioService from './services/twilio.service';
import { loadUserFromStorage } from './utils';

const user = loadUserFromStorage();
const initialStore = {};
if (Object.keys(user).length) {
    initialStore.user = user;
    initialStore.map = { ...MAP_INITIAL, ...{ center: user.location } };
}
export const history = createBrowserHistory();

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              serialize: true,
              trace: false,
              maxAge: 20,
          })
        : compose;

const enhancer = composeEnhancers(
    applyMiddleware(
        filtersSaveToCookie,
        saveUserLocationToCookies,
        thunk.withExtraArgument({
            apolloClient,
            cookieService: CookieService,
            chatClient: new TwilioService(),
        })
    )
);

export const store = createStore(rootReducer, initialStore, enhancer);
