function selectUserListState(state) {
    return state.userList;
}

export function selectUserData(state) {
    return selectUserListState(state).userData;
}

export function selectUser(state, id) {
    return selectUserData(state)[id];
}
