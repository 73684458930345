import { UPDATE_USER_LIST } from '../actions/user-list.actions';

const initialState = {
    userData: {}
};
export function userList(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER_LIST:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...action.payload
                }
            };
        default:
            return state;
    }
}
