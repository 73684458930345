import { gql } from '@apollo/client';
import { SHORT_RESERVATION_FRAGMENT } from '../graphql/reservation-fragments';

export const GET_RESERVATION = 'GET_RESERVATION';
export const SET_RESERVATION = 'SET_RESERVATION';
export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS';
export const GET_RESERVATION_ERROR = 'GET_RESERVATION_ERROR';

export const CONFIRM_RESERVATION = 'CONFIRM_RESERVATION';
export const CONFIRM_RESERVATION_SUCCESS = 'CONFIRM_RESERVATION_SUCCESS';
export const CONFIRM_RESERVATION_ERROR = 'CONFIRM_RESERVATION_ERROR';

export const DECLINE_RESERVATION = 'DECLINE_RESERVATION';
export const DECLINE_RESERVATION_SUCCESS = 'DECLINE_RESERVATION_SUCCESS';
export const DECLINE_RESERVATION_ERROR = 'DECLINE_RESERVATION_ERROR';

export const UPDATE_RESERVATION_SPOT = 'UPDATE_RESERVATION_SPOT';
export const UPDATE_RESERVATION_SPOT_SUCCESS = 'UPDATE_RESERVATION_SPOT_SUCCESS';
export const UPDATE_RESERVATION_SPOT_ERROR = 'UPDATE_RESERVATION_SPOT_ERROR';
export const UPDATE_RESERVATION_SPOT_IDLE = 'UPDATE_RESERVATION_SPOT_IDLE';

export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_ERROR = 'CANCEL_RESERVATION_ERROR';

const jsonify = (string) => {
    return JSON.stringify(string);
};

export const confirmReservation = (code, details) => {
    return (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: CONFIRM_RESERVATION });
        return apolloClient
            .mutate({
                mutation: gql`
                    mutation ConfirmReservation($code: String!, $details: String) {
                        confirmReservation(code: $code, details: $details) {
                            ...ShortReservation
                        }
                    }
                    ${SHORT_RESERVATION_FRAGMENT}
                `,
                variables: {
                    code,
                    details: jsonify(details),
                },
            })
            .then((resp) => {
                dispatch({ type: CONFIRM_RESERVATION_SUCCESS });
                dispatch({
                    type: SET_RESERVATION,
                    data: resp.data.confirmReservation,
                });
                return resp.data.confirmReservation;
            })
            .catch((err) => {
                console.warn('error', err);
                dispatch({ type: CONFIRM_RESERVATION_ERROR });
            });
    };
};

export const declineReservation = (code, details) => {
    return (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: DECLINE_RESERVATION });
        return apolloClient
            .mutate({
                mutation: gql`
                mutation {
                    declineReservation(code: "${code}", details: ${jsonify(details)}) {
                        ...ShortReservation
                    }
                }
                ${SHORT_RESERVATION_FRAGMENT}
                `,
            })
            .then((resp) => {
                dispatch({ type: DECLINE_RESERVATION_SUCCESS });
                dispatch({
                    type: SET_RESERVATION,
                    data: resp.data.declineReservation,
                });
                return resp.data.declineReservation;
            })
            .catch((err) => {
                console.warn('error', err);
                dispatch({ type: DECLINE_RESERVATION_ERROR });
            });
    };
};

export const cancelReservation = (code, details) => {
    return (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: CANCEL_RESERVATION });
        apolloClient
            .mutate({
                mutation: gql`
                mutation {
                    cancelReservation(code: "${code}", details: ${jsonify(details)}) {
                        ...ShortReservation
                    }
                }
                ${SHORT_RESERVATION_FRAGMENT}
                `,
            })
            .then((resp) => {
                dispatch({ type: CANCEL_RESERVATION_SUCCESS });
                dispatch({
                    type: SET_RESERVATION,
                    data: resp.data.cancelReservation,
                });
            })
            .catch((err) => {
                console.warn('error', err);
                dispatch({ type: CANCEL_RESERVATION_ERROR });
            });
    };
};

export const updateReservationSpot = (code, instructions) => {
    return (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: UPDATE_RESERVATION_SPOT });
        apolloClient
            .mutate({
                mutation: gql`
                mutation {
                    updateReservationSpot(code: "${code}", instructions: ${jsonify(instructions)})
                }`,
            })
            .then(() => {
                dispatch({ type: UPDATE_RESERVATION_SPOT_SUCCESS });
            })
            .catch((err) => {
                console.warn('error', err);
                dispatch({ type: UPDATE_RESERVATION_SPOT_ERROR });
            });
    };
};
