import { Client } from '@twilio/conversations';
import { CLIENT_STATES } from '../consts/messaging.consts';

class TwilioService {
    messagingClient = null;
    paginatorData = {};
    subscribeData = {};

    eventSubscriptions = [];

    get clientState() {
        return this.messagingClient?.connectionState;
    }

    async createClient(token) {
        await this.shutdown();
        this.messagingClient = new Client(token);
        this.eventSubscriptions = [];
        this.paginatorData = {};
    }

    async shutdown() {
        if (this.messagingClient) {
            try {
                await this.messagingClient.shutdown();
                this.messagingClient = null;
                this.eventSubscriptions = [];
                this.paginatorData = {};
                this.subscribeData = {};
            } catch (e) {
                console.warn('shutdown error', e);
            }
        }
    }

    async sendMessageToChannel(channelSID, message) {
        return this._sendMessageToChannel(channelSID, message).then((res) => {
            if (res) {
                return res;
            } else {
                return new Promise((resolve) => {
                    let qty = 3;
                    const queueInterval = setInterval(() => {
                        console.info('inner');
                        qty--;
                        if (this.clientState === CLIENT_STATES.CONNECTED) {
                            clearInterval(queueInterval);
                            this._sendMessageToChannel(channelSID, message).then((sent) => {
                                resolve(sent);
                            });
                        }
                        if (!qty) {
                            console.warn('error establising connection');
                            clearInterval(queueInterval);
                            resolve(false);
                        }
                    }, 500);
                });
            }
        });
    }

    async _sendMessageToChannel(channelSID, message) {
        try {
            const channel = await this.messagingClient.getConversationBySid(channelSID);
            await channel.sendMessage(message);
        } catch (e) {
            console.warn('sending message error', e);
            return false;
        }
        return true;
    }

    async sendTextImageToChannel(channelSID, message, image) {
        try {
            const channel = await this.messagingClient.getConversationBySid(channelSID);
            await channel.prepareMessage().setBody(message).addMedia(image).build().send();
        } catch (e) {
            console.warn('sending message error', e);
            return false;
        }
        return true;
    }

    setSubscribed(channel, eventName) {
        if (!this.subscribeData[eventName]) {
            this.subscribeData[eventName] = {};
        }
        this.subscribeData[eventName][channel.sid] = true;
    }

    isSubscribed(channel, eventName) {
        return this.subscribeData[eventName] && this.subscribeData[eventName][channel.sid];
    }

    async updateToken(token) {
        this.messagingClient = await this.messagingClient.updateToken(token);
    }

    on(event, handler) {
        this.eventSubscriptions.push({ event, handler });
        return this.messagingClient.on(event, handler);
    }

    off(event, handler) {
        this.eventSubscriptions = this.eventSubscriptions.filter(({ e, h }) => e !== event || h !== handler);
        return this.messagingClient.off(event, handler);
    }

    getSubscribedChannels() {
        return this.messagingClient.getSubscribedConversations();
    }

    getChannelBySid(sid) {
        return this.messagingClient.getConversationBySid(sid);
    }

    getChannelByUnique(uni) {
        return this.messagingClient.getConversationByUniqueName(uni);
    }

    getPaginator(paginatorId) {
        return this.paginatorData[paginatorId];
    }

    setPaginator(paginatorId, paginator) {
        this.paginatorData[paginatorId] = paginator;
    }

    async sendTypingEvent(sid) {
        const activeChannel = await this.getChannelBySid(sid);
        activeChannel.typing();
    }
}

export default TwilioService;
