import { QUERY_DOGS_LIST } from '../graphql/dog-queries';

export const UPDATE_DOGS_INFO = 'UPDATE_DOGS_INFO';

export const listDogs =
    (ids) =>
    async (dispatch, getState, { apolloClient }) => {
        try {
            const { data, errors } = await apolloClient.query({ query: QUERY_DOGS_LIST, variables: { ids } });
            if (!errors) {
                dispatch({ type: UPDATE_DOGS_INFO, payload: data.dogs });
            } else {
                console.warn(errors);
            }
            return !errors;
        } catch (e) {
            console.warn(e);
            return false;
        }
    };
