import { LISTING_SEARCH_MODS } from '../consts/listings-search.consts';
import {
    SEARCH_LISTINGS_FOCUS,
    SET_ALL_SET,
    SHOW_SUBSCRIBE_MODAL,
    SMART_BANNER_CLOSE,
    SMART_BANNER_SHOW,
} from '../actions/app.actions';
import { checkPlatform } from '../utils';

export const INIT_STATE_API_DATA = {
    showMode: LISTING_SEARCH_MODS.LIST_VIEW,
    platform: checkPlatform(),
    screen: {
        isVertical: window.innerWidth < window.innerHeight,
    },
    isShowSubscribeModal: false,
    allSet: null,
    isSmartBannerShow: false,
    isShowMoreSpotsBtn: false,
    isSearchListingsFocus: false,
};

export const app = (state = INIT_STATE_API_DATA, action) => {
    switch (action.type) {
        case 'DEFAULT':
            return {
                ...state,
            };
        case 'REVIEW_FROM_HOME':
            return {
                ...state,
                reviewFromHome: true,
            };
        case 'REVIEW_FROM_DEFAULT':
            return {
                ...state,
                reviewFromHome: false,
            };
        case 'SAVE_SHOW_MODE':
            return {
                ...state,
                showMode: action.value,
            };
        case 'SHOW_MORE_SPOTS_BTN':
            return {
                ...state,
                isShowMoreSpotsBtn: true,
            };
        case 'HIDE_MORE_SPOTS_BTN':
            return {
                ...state,
                isShowMoreSpotsBtn: false,
            };
        case SHOW_SUBSCRIBE_MODAL:
            return {
                ...state,
                isShowSubscribeModal: action.value,
            };
        case SMART_BANNER_CLOSE:
            return {
                ...state,
                isSmartBannerShow: false,
            };
        case SMART_BANNER_SHOW:
            return {
                ...state,
                isSmartBannerShow: true,
            };
        case SET_ALL_SET:
            return {
                ...state,
                allSetId: action.value,
            };
        case SEARCH_LISTINGS_FOCUS:
            return {
                ...state,
                isSearchListingsFocus: action.value,
            };
    }
    return state;
};
